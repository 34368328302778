:root {
  --default-color: rgb(1.5, 1.5, 1.5);
  --darker-color-variant: 8, 8, 8;
  --darker-color: rgb(var(--darker-color-variant));
  --third-color: rgb(17, 17, 17);
  --third-color-transparent: rgba(17, 17, 17, .55);
  --third-color-2: rgb(15, 15, 15);
  --default-light-color-2: #2a2b2a18;
  --default-light-color: #2a2b2a48;
  --secondary-light-variant: 0, 144, 255;
  --secondary-light-color: rgb(var(--secondary-light-variant));
  // --secondary-light-color: rgb(var(--secondary-light-variant));
  --secondary-light-color-2: rgba(var(--secondary-light-variant), 0.404);
  --secondary-light-color-3: rgba(var(--secondary-light-variant), 0.094);
  --text-primary-color: #f5f5f5;
  --text-primary-color-2: #d4d1d1;
  --text-secondary-color: #636161;
  --text-secondary-color-light: #ececec;
  --text-secondary-color-light-2: #ecececab;
  --text-secondary-color-2: #79797931;
  --max-w: 1180px;
  --max-w-2: 1000px;
  --max-w-3: 900px;
  --nav-max-w: 1030px;
  --pad-to-mw: calc(50% - var(--max-w)/2);
  --pad-to-mw2: calc(50% - var(--max-w-2)/2);
}

html {
  scroll-behavior: smooth;
}

#small_desc {
  opacity: 0;
  margin-top: 2rem;

  & .word {
    opacity: 0;
    transform: translateY(1.2rem) rotateX(90deg) rotateY(0deg) rotateZ(0deg);
  }
}

.max-nav {
  max-width: var(--nav-max-w);
}

.w-wrapped {
  min-width: calc(var(--max-w)/4);
}

#folio-carousel {
  background-color: transparent;
  padding-left: var(--pad-to-mw);

  @media screen and (max-width:1260px) {
    padding-left: var(--pad-to-mw2);
  }

  @media screen and (max-width:1024px) {
    padding-left: 5vw;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.timeline-container {
  width: 50%;
  position: relative;
  margin-top: 3rem;
  padding: 0rem 2rem;
  text-align: center;
  visibility: hidden;
  border-radius: 1rem;
  // border: 1px solid var(--secondary-light-color);
  transition: all .5s ease;
  opacity: 0;


  @media screen and (max-width:750px) {
    width: 90vw !important;
    max-width: 500px;
    text-align: start;
    padding: 0;
    padding-left: 8vw;
    margin: 0 auto;
    margin-top: 3rem;
  }

  &.activated {
    visibility: visible;
    opacity: 1;

    &.left {
      margin-left: -1rem;
    }

    &.right {
      margin-left: calc(50% + 1rem);

      @media screen and (max-width:750px) {
        margin-left: -1rem;
      }
    }
  }


  & .period {
    position: absolute;
    top: -1rem;
    z-index: 10;

    &.left {
      right: -2rem;

      @media screen and (max-width:750px) {
        right: calc(100% - 2rem) !important;
      }
    }

    &.right {
      left: -2rem;

      @media screen and (max-width:750px) {
        left: 0;
      }
    }
  }

  &.left {
    // margin-right: 50%;
    margin-left: -4rem;
  }

  &.right {
    margin-left: calc(50% + 4rem);
  }
}

.select-none {
  -webkit-user-drag: none !important;
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-drag: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
}

@keyframes popInView {
  0% {
    transform: translateX(30%) translateY(20%);
    opacity: 0;
  }

  40% {
    transform: translateX(0%) translateY(0%);
    opacity: 1;
  }

  90% {
    transform: translateX(0%) translateY(0%);
    opacity: 1;
  }

  100% {
    transform: translateX(30%) translateY(20%);
    opacity: 0;
  }
}

.magic_animate {
  animation: popInView 5s ease infinite;
}

#btn {
  box-shadow: 5px 5px 5rem var(--secondary-light-color-2);
}

.shadow_secondary {
  box-shadow: 5rem 10rem 15rem var(--secondary-light-color-3);
}

.c_shad {
  box-shadow: 5px 5px 15rem var(--secondary-light-color);
}

.blured {
  backdrop-filter: blur(.5rem);

  &.sm {
    backdrop-filter: blur(.2rem);
  }
}

.shad {
  &:active {
    box-shadow: none !important;
  }

  &:hover {
    box-shadow: 5px 5px .8rem var(--secondary-light-color-2);
  }
}

#btn:active {
  box-shadow: none;
}

#navigator {
  position: relative;

  &:hover {
    color: var(--secondary-light-color);
  }

  &:hover::after {
    width: 100%;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -.5rem;
    background-color: var(--secondary-light-color);
    left: 0px;
    width: 0px;
    transition: all .2s ease-out;
    height: 1px;
    z-index: 2;
  }
}

.stack-items {
  position: relative;
  overflow: hidden;

  &-info {
    position: absolute;
    z-index: 10;
    inset: 0;
    opacity: 0;
    background: rgba(var(--darker-color-variant), .85);
    display: flex;
    justify-content: center;
    transition: all .2s ease-in;
    align-items: center;
    font-size: .9rem;
    font-family: 'Montserrat-Bold';

    & p {
      text-align: center;
      text-transform: uppercase;
      width: fit-content;
    }
  }

  &:hover &-info {
    opacity: 1;
    color: white;
  }

}

#input-parent {
  border-width: 1px;

  &:focus-within {
    border-color: var(--secondary-light-color);
    border-width: 1px;
  }
}

.max-w {
  transition: all .2s ease-in;
  max-width: var(--max-w) !important;

  @media screen and (max-width:1260px) {
    max-width: var(--max-w-2) !important;
  }


  @media screen and (max-width:1024px) {
    max-width: var(--max-w-3) !important;
  }
}

@keyframes moveToRight {
  0% {
    transform: translateX(-20%);
  }

  50% {
    transform: translateX(50%);
  }

  100% {
    transform: translateX(-20%);
  }
}

@keyframes moveToRight2 {
  0% {
    transform: translateX(-20%);
  }

  50% {
    transform: translateX(10%);
  }

  100% {
    transform: translateX(-20%);
  }
}

@keyframes moveToLeft {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(-200%) translateY(-10rem);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes moveToLeft2 {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(5%) translateY(-5rem);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes moveToCenter {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(5%) translateY(-5rem);
  }

  100% {
    transform: translateX(0%);
  }
}


@keyframes moveInZigZag {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(150%) translateY(-17rem);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes moveInZigZag2 {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(-5%) translateY(-2rem);
  }

  100% {
    transform: translateX(0%);
  }
}

// #ctx {
//   z-index: 0;

//   & .g2 {
//     position: absolute;
//     background: linear-gradient(to bottom, transparent 50%, var(--default-color) 50%),
//       radial-gradient(circle farthest-side,
//         rgba(var(--secondary-light-variant), .3),
//         rgba(var(--secondary-light-variant), .2) .5%,
//         rgba(var(--secondary-light-variant), .19) 1%,
//         rgba(var(--secondary-light-variant), .18) 2%,
//         rgba(var(--secondary-light-variant), .17) 5%,
//         rgba(var(--secondary-light-variant), .16) 10%,
//         rgba(var(--secondary-light-variant), .15) 15%,
//         rgba(var(--secondary-light-variant), .12) 25%,
//         rgba(var(--secondary-light-variant), .1) 50%,
//         rgba(100, 200, 158, 0)) no-repeat;
//     width: 35rem;
//     height: 35rem;
//     bottom: 0rem;
//     right: 0;
//     mix-blend-mode: hard-light;
//     opacity: 1;
//     z-index: -5;
//     transform-origin: center center;
//     animation: moveToLeft 30s ease infinite;
//   }
// }


#rootContainer {
  margin: 0 auto;
  // max-width: var(--max-w);
  padding: 1.25rem 0rem;
  position: relative;
  min-height: 100vh;
  z-index: 0;
  backdrop-filter: blur(2rem);
  // filter: url(#goo) blur(40px);
  // overflow-x: hidden;
  z-index: 1;


  // @media screen and (max-width:1000px) {
  //   padding: 1.25rem 1rem;
  // }

  & .g1 {
    @media screen and (min-width:1700px) {
      display: none;
    }

    position: absolute;
    background: radial-gradient(circle farthest-side,
      rgba(var(--secondary-light-variant), .9),
      rgba(var(--secondary-light-variant), .18) 10%,
      rgba(var(--secondary-light-variant), .15) 15%,
      rgba(var(--secondary-light-variant), .12) 25%,
      rgba(var(--secondary-light-variant), .1) 50%,
      rgba(100, 200, 158, 0)) no-repeat;
    width: 35rem;
    height: 35rem;
    top: calc(-45rem/2);
    left: 0;
    mix-blend-mode: hard-light;
    opacity: 1;
    z-index: -5;
    transform-origin: center center;
    animation: moveToRight 30s ease infinite;

    @media only screen and (max-width:1000px) {
      animation: none;
      left: -90%;
    }

  }

  & .g2 {

    @media screen and (min-width:1700px) {
      display: none;
    }


    @media screen and (max-width:700px) {
      animation: moveToLeft 50s ease infinite;
    }

    position: absolute;
    background: radial-gradient(circle farthest-side,
      rgba(var(--secondary-light-variant), .1),
      rgba(var(--secondary-light-variant), .09) .5%,
      rgba(var(--secondary-light-variant), .08) 1%,
      rgba(var(--secondary-light-variant), .07) 2%,
      rgba(var(--secondary-light-variant), .06) 5%,
      rgba(var(--secondary-light-variant), .05) 10%,
      rgba(var(--secondary-light-variant), .04) 15%,
      rgba(var(--secondary-light-variant), .03) 25%,
      rgba(var(--secondary-light-variant), .01) 50%,
      rgba(100, 200, 158, 0)) no-repeat;
    width: 45rem;
    height: 45rem;
    bottom: 0;
    right: 0;
    mix-blend-mode: hard-light;
    opacity: 1;
    z-index: -5;
    transform-origin: center center;
    animation: moveToLeft 50s ease infinite;
  }
}

// @keyframes rotation {
//   0% {
//     transform: rotate(0deg);
//   }

//   100% {
//     transform: rotate(360deg);
//   }
// }

// #animated_linear {
//   position: relative;
//   background: #000;
//   z-index: 0;

//   &::before {
//     content: "";
//     position: absolute;
//     z-index: -5;
//     background: conic-gradient(red, transparent);
//     animation: rotation 50s ease infinite;
//     inset: 0;
//   }
// }

#about_bg {
  background: linear-gradient(to bottom right, var(--default-color) 70%, var(--secondary-light-color-2));
  // height: 450px;
}

@media screen and (max-width:750px) {
  #about_bg {
    height: auto;
  }
}

.border_shadowed {
  border: 1px solid var(--secondary-light-color-2);
}

.shadow_primary {
  box-shadow: 0px -5px 20px 1rem rgba(0, 0, 0, 0.288), 0px 5px 20px .5rem var(--secondary-light-color-3);
}

// @keyframes slideTop  {

// }

#folios-items,
#contact-form {
  opacity: 0;
  margin-top: 5rem;
  transition: all .2s ease-out;
  transition-delay: var(--iteration);
}

#stack_item {
  opacity: 0;
  margin-top: .5rem;
  transform: scale(.2);
  transition: all .15s ease-out;
  transition-delay: var(--iteration);
}

#services {
  // background: var(--darker-color);
  color: white;
  height: auto;
  width: 100%;
  max-width: 360px;
  opacity: 0;
  margin-top: -5rem;
  transition: all .2s ease-out;
  transition-delay: var(--iteration);

  &:hover {
    box-shadow: none;
  }
}

#portfolio {
  position: relative;

  &::after {

    @media screen and (min-width:1700px) {
      display: none;
    }

    @media screen and (max-width:700px) {
      animation: moveInZigZag2 50s ease infinite;
    }


    content: "";
    position: absolute;
    background: radial-gradient(circle farthest-side,
      rgba(var(--secondary-light-variant), .13),
      rgba(var(--secondary-light-variant), .12) .5%,
      rgba(var(--secondary-light-variant), .11) 1%,
      rgba(var(--secondary-light-variant), .10) 2%,
      rgba(var(--secondary-light-variant), .09) 5%,
      rgba(var(--secondary-light-variant), .08) 10%,
      rgba(var(--secondary-light-variant), .07) 15%,
      rgba(var(--secondary-light-variant), .06) 25%,
      rgba(var(--secondary-light-variant), .05) 50%,
      rgba(100, 200, 158, 0)) no-repeat;
    width: 40rem;
    height: 40rem;
    top: calc(50% /2);
    left: 0;
    mix-blend-mode: hard-light;
    opacity: 1;
    z-index: -5;
    transform-origin: center center;
    animation: moveInZigZag 50s ease infinite;
  }
}


#experiences {
  position: relative;

  &::after {

    @media screen and (min-width:1700px) {
      display: none;
    }

    @media screen and (max-width:700px) {
      animation: moveInZigZag2 50s ease infinite;
    }


    content: "";
    position: absolute;
    background: radial-gradient(circle farthest-side,
      rgba(var(--secondary-light-variant), .13),
      rgba(var(--secondary-light-variant), .12) .5%,
      rgba(var(--secondary-light-variant), .11) 1%,
      rgba(var(--secondary-light-variant), .10) 2%,
      rgba(var(--secondary-light-variant), .09) 5%,
      rgba(var(--secondary-light-variant), .08) 10%,
      rgba(var(--secondary-light-variant), .07) 15%,
      rgba(var(--secondary-light-variant), .06) 25%,
      rgba(var(--secondary-light-variant), .05) 50%,
      rgba(100, 200, 158, 0)) no-repeat;
    width: 40rem;
    height: 40rem;
    bottom: calc(50% /2);
    left: 0;
    mix-blend-mode: hard-light;
    opacity: 1;
    z-index: -5;
    transform-origin: center center;
    animation: moveInZigZag 50s ease infinite;
  }
}

body {
  background-color: var(--default-color) !important;
  color: var(--text-primary-color) !important;
  min-height: 100vh !important;
  font-family: "Montserrat-Light" !important;
  transition: all .2s;
}

// ::-webkit-scrollbar {
//   // display: none;
//   opacity: 0;
// }

::-webkit-scrollbar {
  display: none;
}

#modal-ctx {
  &::-webkit-scrollbar {
    // background: white;
    // display: initial;
    // width: 5px;
    // opacity: 1 !important;
    display: initial !important;
    width: 5px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--secondary-light-color);
    border-radius: 20px;
  }
}

.font-MontRegular {
  font-family: "Montserrat-Regular";
}

.font-MontMedium {
  font-family: "Montserrat-Medium";
}

.font-MontSemiBold {
  font-family: "Montserrat-SemiBold";
}

.font-MontBold {
  font-family: "Montserrat-Bold";
}

.font-MontExtraBold {
  font-family: "Montserrat-ExtraBold";
}

.font-MontLight {
  font-family: "Montserrat-Light";
}

#who_iam {
  background: -webkit-linear-gradient(-45deg, var(--text-primary-color) 50%, var(--secondary-light-color) 85%);
  -webkit-background-clip: text;
  background-size: 200% 200%;
  animation: gradient 5s ease infinite;
  -webkit-text-fill-color: transparent;

  @media only screen and (max-width:750px) {
    font-family: "Montserrat-ExtraBold";
  }
}


@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 90% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.about {
  // background-color: var(--default-light-color-2);
  margin: 3rem auto;
  margin-bottom: 5rem;
  border-radius: 100px;
  padding: 2.5rem 2rem;
  display: flex;
  align-items: center;
  border: 1px solid var(--text-secondary-color-2);
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;

  @media only screen and (max-width: 768px) {
    border-radius: 20px;
  }

}

.line {
  position: absolute;
  content: "";
  z-index: 2;
  top: 0;
  left: 50%;
  height: 0;
  width: 2px;
  background-color: white;
  transform: translateX(-50%);
}

.list__ {
  list-style-type: disc !important;
  list-style: circle !important;
}

.mobNav_item {
  padding: .35rem 1rem;
  transition: all .2s ease-in-out;
  border-radius: 50px;
  color: var(--text-secondary-color-light);
  font-family: "Montserrat-Semibold";
  margin-bottom: 1rem;
  text-align: center;

  &:hover {
    background-color: var(--text-primary-color);
    color: var(--darker-color);
    // font-family: "Montserrat-SemiBold";
  }

  &.activated {
    background-color: var(--text-primary-color);
    color: var(--darker-color);
  }
}

.nav {
  background-color: var(--default-light-color-2);
  max-width: calc(var(--nav-max-w) + 2rem);
  // min-width: 90%;
  // margin: 0 auto;
  border-radius: 50px;
  padding: .35rem .5rem;
  display: flex;
  align-items: center;
  border: 1px solid var(--text-secondary-color-2);
  justify-content: space-between;
  gap: .5rem;

  &.vertical {
    flex-direction: column;
    background-color: transparent;
    border: none;
  }


  &-items {
    display: flex;
    gap: 5;
    align-items: center;
    gap: .25rem;
    list-style: none;

    @media screen and (max-width:1100px) {
      display: none;
    }

    & li.activated {
      background-color: var(--text-primary-color);
      color: var(--darker-color);
    }

    & li {
      padding: .35rem 1rem;
      transition: all .2s ease-in-out;
      border-radius: 50px;
      color: var(--text-secondary-color-light);
      font-family: "Montserrat-Semibold";

      &:hover {
        background-color: var(--text-primary-color);
        color: var(--darker-color);
        // font-family: "Montserrat-SemiBold";
      }
    }
  }

  &-name {
    display: flex;
    font-size: 1.2rem;
    align-items: center;
    margin-left: 1rem;

    @media screen and (max-width:1000px) {
      margin-left: 0;
    }

    &-subTitle {
      color: var(--secondary-light-color);
      font-weight: 500;
      font-size: 1.5rem;
    }
  }
}