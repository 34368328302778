@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::placeholder {
  font-family: 'Montserrat-Regular';
  opacity: 1; /* Firefox */
}


@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), url(./assets/fonts/Montserrat/static/Montserrat-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


@font-face {
  font-family: 'Montserrat-Medium';
  src: local('Montserrat-Medium'), url(./assets/fonts/Montserrat/static/Montserrat-Medium.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: local('Montserrat-SemiBold'), url(./assets/fonts/Montserrat/static/Montserrat-SemiBold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


@font-face {
  font-family: 'Montserrat-Bold';
  src: local('Montserrat-Bold'), url(./assets/fonts/Montserrat/static/Montserrat-Bold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


@font-face {
  font-family: 'Montserrat-Light';
  src: local('Montserrat-Light'), url(./assets/fonts/Montserrat/static/Montserrat-Light.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}




@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: local('Montserrat-ExtraBold'), url(./assets/fonts/Montserrat/static/Montserrat-ExtraBold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
